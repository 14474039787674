<script>
import Layout from '@/router/layouts/main';
import PageHeader from '@/components/page-header';
import Datatable from '@/router/layouts/table-datatable.vue';
import Pagination from '@/router/layouts/table-pagination.vue';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  components: {
    Layout,
    PageHeader,
    Datatable,
    Pagination,
  },
  data() {
    let columns = [
      { width: '50px', label: '', name: '-' },
      { width: 'auto', label: 'NIP', name: '-' },
      { width: 'auto', label: 'Nama', name: '-' },
      { width: 'auto', label: 'IP Address', name: '-' },
      { width: 'auto', label: 'Browser', name: '-' },
      { width: 'auto', label: 'Waktu Login', name: '-' },
      { width: '100px', label: '', name: '-' },
    ];
    return {
      title: 'Log Peserta',
      items: [
        {
          text: 'Log Peserta',
          active: true,
        },
      ],
      isLoading: false,
      columns: columns,
      tableFilter: {
        page: 1,
        per_page: 10,
        nip: '',
        nama: '',
        status: 1,
      },
      tableData: [],
      pagination: {
        lastPage: '',
        currentPage: '',
        total: '',
        lastPageUrl: '',
        nextPageUrl: '',
        prevPageUrl: '',
        from: '',
        to: '',
        links: '',
      },
      listPesertaDitandai: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const data = await axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + 'log-peserta', {
          params: this.tableFilter,
          headers: {
            Authorization: 'Bearer ' + localStorage.access_token,
            Accept: 'Application/json',
          },
        })
        .then((response) => response.data)
        .catch((err) => {
          let errResponse = err.response.data;
          if (errResponse.meta?.code == 403) {
            Swal.fire({
              icon: 'error',
              title: 'Terjadi Kesalahan',
              text: errResponse.meta.message,
            });
            this.$router.push({ name: 'logout' });
            return false;
          }
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: errResponse.data,
          });

          console.log(errResponse);
          return false;
        });

      if (data) {
        this.tableData = data.data.data;
        this.pagination.lastPage = data.data.last_page;
        this.pagination.currentPage = data.data.current_page;
        this.pagination.total = data.data.total;
        this.pagination.lastPageUrl = data.data.last_page_url;
        this.pagination.nextPageUrl = data.data.next_page_url;
        this.pagination.prevPageUrl = data.data.prev_page_url;
        this.pagination.from = data.data.from;
        this.pagination.to = data.data.to;
        this.pagination.links = data.data.links;
      }
      this.isLoading = false;
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get('page');
      // this.tableFilter.per_page = link.get('per_page');
      this.getData();
    },
    kick(row) {
      Swal.fire({
        icon: 'warning',
        title: 'Perhatian',
        text: 'Apakah Anda Yakin mengeluarkan peserta ini ?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Iya`,
        denyButtonText: `Tidak`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log(row);
          const data = await axios({
            method: 'delete',
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              'log-peserta/' +
              row.idlog_peserta,
            headers: {
              Authorization: 'Bearer ' + localStorage.access_token,
            },
          })
            .then((response) => response.data.data)
            .catch((error) => {
              console.log(error);
              return false;
            });
          if (data) {
            this.alertBerhasil();
          }
        } else {
          Swal.close();
        }
      });
    },
    async kickMultiple() {
      const data = await axios({
        method: 'delete',
        url: process.env.VUE_APP_BACKEND_URL_VERSION + 'log-peserta',
        data: {
          idlog_peserta: this.listPesertaDitandai,
        },
        headers: {
          Authorization: 'Bearer ' + localStorage.access_token,
        },
      })
        .then((response) => response.data.data)
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: 'warning',
            title: 'Terjadi Kesalahan',
            timer: 3000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        });
      if (data) {
        this.alertBerhasil();
      }
    },
    async kickAll() {
      const data = await axios({
        method: 'delete',
        url: process.env.VUE_APP_BACKEND_URL_VERSION + 'log-peserta/all',
        headers: {
          Authorization: 'Bearer ' + localStorage.access_token,
        },
      })
        .then((response) => response.data.data)
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: 'warning',
            title: 'Terjadi Kesalahan',
            timer: 3000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          });
          return false;
        });
      if (data) {
        this.alertBerhasil();
      }
    },
    alertBerhasil() {
      let timerInterval;
      Swal.fire({
        icon: 'success',
        title: 'Berhasil',
        text: 'Data berhasil dihapus.',
        timer: 2000,
        timerProgressBar: true,
        showCancelButton: false,
        showConfirmButton: false,
        didOpen: () => {
          timerInterval = setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector('b');
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          this.getData();
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="form-group col-md-2">
                <label for="filterJabatan">NIP</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Filter NIP"
                  v-model="tableFilter.nip"
                />
              </div>
              <div class="form-group col-md-2">
                <label for="filterJabatan">Nama</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Filter Nama"
                  v-model="tableFilter.nama"
                />
              </div>
              <div class="form-group col-md-1 d-flex align-items-end">
                <div>
                  <button class="btn btn-primary" @click="getData()">
                    <i class="fa fa-search me-1"></i>Filter
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-between mb-2">
              <div>
                <table>
                  <tr>
                    <td>Show</td>
                    <td>
                      <select
                        class="form-control form-control-sm"
                        id="showpaginatetable"
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                      </select>
                    </td>
                    <td>Entries</td>
                  </tr>
                </table>
              </div>
              <div>
                <!-- <input
                  type="text"
                  class="form-control form-control-sm"
                  id="searchTable"
                  placeholder="Cari Data ..."
                /> -->
                <div class="float-end">
                  <button class="btn btn-warning btn-sm" @click="kickAll()">
                    <i class="fa fa-ban me-1"></i> Kick Semua Peserta Login
                  </button>
                  &nbsp;
                  <button
                    class="btn btn-danger btn-sm"
                    :disabled="!listPesertaDitandai.length"
                    @click="kickMultiple()"
                  >
                    <i class="fa fa-ban me-1"></i> Kick Peserta Ditandai
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <Datatable :columns="columns">
                <tbody>
                  <tr v-if="isLoading">
                    <td class="text-center" colspan="99">
                      <i class="fas fa-spinner fa-spin"></i> Loading...
                    </td>
                  </tr>
                  <tr v-else-if="tableData == ''">
                    <td class="text-center" colspan="99">
                      Data Tidak Tersedia
                    </td>
                  </tr>
                  <tr v-else v-for="row in tableData" :key="row.idlog_peserta">
                    <td class="text-center">
                      <input
                        type="checkbox"
                        class="largerCheckbox"
                        :value="row.idlog_peserta"
                        v-model="listPesertaDitandai"
                      />
                    </td>
                    <td>
                      {{ row.nip }}
                    </td>
                    <td>
                      {{ row.nama }}
                    </td>
                    <td>
                      {{ row.ip }}
                    </td>
                    <td>
                      {{ row.browser }}
                    </td>
                    <td>
                      {{ row.created_at }}
                    </td>
                    <td class="text-center">
                      <button class="btn btn-danger btn-sm" @click="kick(row)">
                        <i class="fa fa-ban me-1"></i> Kick
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Datatable>
            </div>
            <pagination :pagination="pagination" @to-page="toPage"></pagination>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
input.largerCheckbox {
  width: 20px;
  height: 20px;
}
</style>
